import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import cenitLogo from '../images/logo-CENIT2-1.webp';
import cenitPartLogo from '../images/CENIT2-partners.webp';

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null
      //content: ""
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/login" });
    else this.setState({ redirect: "/profile" });

    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="container">
        <div className="smtDiv jumbotron">
          <h3>{this.state.content}</h3>
          <img src={cenitLogo} className="width75 borderRadius05"></img>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-10 smtpText">
              <p>CENIT2.0</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-4 col-md-6 mb-4 mb-md-0">
            <a href="https://logikers.com/cenit-2/" target={"_blank"}><img src={cenitPartLogo} className="width100 borderRadius05"></img></a>
          </div>
        </div>
      </div>
    );
  }
}