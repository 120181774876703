import { Component } from "react";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
import { withRouter } from "../common/with-router";
import AuthService from "../services/auth.service";
import Tabs from "./tabs";

const tabData = [
  { label: "Generación fotovoltaica", collection: "usecase1" },
  { label: "Fotovoltaica con almacenamiento", collection: "usecase2" },
  { label: "Fotovoltaica: almacenamiento y vehículo", collection: "usecase3" },
  { label: "Fotovoltaica: almacenamiento, vehículo y servicios de red", collection: "usecase4"}
]

 class DataUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            redirect: null,
            currentUser: {},
            message: ""
        };
    }
  
    componentDidMount() {
      // const currentUser = AuthService.getCurrentUser();
      // if (!currentUser) this.setState({ redirect: "/login" });

      UserService.getUserGridData().then(
        response => {
          if(response && response.data && response.data.user) {
            localStorage.setItem("user", JSON.stringify(response.data.user));
          }
            this.setState({
                content: response.data,
                currentUser: response.data.user,
            });
        },
        error => {
          const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          this.setState({
            message: resMessage,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.title) ||
              error.title ||
              error.toString()
          });
        }
      );
    }
  
    render() {
      if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />
      }
      return (
        <div className="container">
          <header className="jumbotron">
            <h2>{(this.state.content.title || this.state.content)}</h2>
          </header>
          <div className="row ">
            {<Tabs tabs={tabData}/>}
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  export default withRouter(DataUser);