import React, { Component } from "react";
import logiLogo from '../images/logiLogo.png';
import ueLogo from '../images/UE-bandera.png';
import cenitPartLogo from '../images/CENIT2-partners.webp';

export default class Footer extends Component {
    render() {
        return (
            <></>
            // <footer className="divFooter">
            //     <div className="row">
            //         <div className="col-lg-1 col-md-6 mb-4 mb-md-0 alignCenter">
            //             <img src={ueLogo} className="ueBandera width25 marginTop2rem borderRadius05"></img>
            //         </div>
            //         <div className="col-lg-3 col-md-6 mb-4 mb-md-0 alignCenter borderRadius05 backGrey">
            //             <a href="https://logikers.com/cenit-2/" target={"_blank"}><img src={cenitPartLogo} className="width100"></img></a>
            //         </div>
            //         <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
            //             <p className=""><u>Planificación y gestión automatizada de Comunidades Energéticas Inteligentes (2.0)</u></p>
            //             <p>
            //             El proyecto CENIT2.0 (AEI-010500-2023-52) ha sido apoyado por el Ministerio de Industria, Comercio y Turismo, así como de la Unión Europea a través del Plan de Recuperación, Transformación y Resiliencia, dentro del programa de apoyo a las AEI.
            //             </p>
            //         </div>
            
            //         <div className="col-lg-2 col-md-6 mb-4 mb-md-0 alignCenter">
            //             <a href="https://logikers.com" target={"_blank"}><img src={logiLogo} className="width50 marginTop2rem borderRadius05"></img></a>
            //         </div>
            //     </div>
            // </footer>
        )
    }
}