import { Component } from "react";
import { Navigate } from "react-router-dom";
import Form from "react-validation/build/form";
import UserService from "../services/user.service";
import { withRouter } from "../common/with-router";
import AuthService from "../services/auth.service";
import BalanceInterval from "./balanceInterval";


 class TxUser extends Component {
    constructor(props) {
        super(props);
        this.handleToken = this.handleToken.bind(this);
        this.state = {
            content: "",
            redirect: null,
            currentUser: {},
            message: "",
            balance: null
        };
    }
  
    componentDidMount() {
      // const currentUser = AuthService.getCurrentUser();
      // if (!currentUser) this.setState({ redirect: "/login" });

      UserService.getTxBoard().then(
        response => {
          if(response && response.data && response.data.user) {
            localStorage.setItem("user", JSON.stringify(response.data.user));
          }
            this.setState({
                content: response.data,
                currentUser: response.data.user,
                balance: response.data.balance
            });
        },
        error => {
          const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          this.setState({
            message: resMessage,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.title) ||
              error.title ||
              error.toString()
          });
        }
      );
    }

    handleToken(e) {
      e.preventDefault();

      this.setState({
        message: "",
        loading: true
      });
      AuthService.buyTokens().then(
        response => {
          this.setState({
            balance: response.tokens,
            loading: false,
            message: ""
          })
        }, 
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      )
    }

    getCenitToken() {
      return (
        <div className="col-md-3 col-lg-3">
          <Form
            onSubmit={this.handleToken}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
                <button
                  className="btn btn-primary margin01"
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Comprar CNIT</span>
                </button>
              </div>
          </Form>
        </div>
      )
    }
  
    render() {
      if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />
      }
      return (
        <div className="container">
          <header className="jumbotron">
            <h2>{(this.state.content.title || this.state.content)}</h2>
          </header>
          <div className="row ">
            {(typeof this.state.balance === 'number') && <BalanceInterval balance={this.state.balance}/>}
            {this.getCenitToken()}
            <div className="col-md-5 col-lg-5">
              <p>
                <strong>Clave pública:</strong>{" "}
                {(this.state.currentUser && this.state.currentUser.wallet && this.state.currentUser.wallet != "") ? this.state.currentUser.wallet : "No añadida"}{" "}
              </p>
            </div>
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  export default withRouter(TxUser);