import axios from 'axios';
import authHeader from './auth.header';

const API_URL = 'https://cenit20.logikers.com/auth/';
//const API_URL = '/auth/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL);
  }

  getUserBoard() {
    return axios.get(API_URL + 'transactions', { headers: authHeader() });
  }

  getTxBoard() {
    return axios.get(API_URL + 'operations', { headers: authHeader() });
  }

  getUserBalance() {
    return axios.get(API_URL + 'getBalance', { headers: authHeader() });
  }

  getUserGridData() {
    return axios.get(API_URL + 'gridToday', { headers: authHeader() });
  }

  getUserGridDataWithDate(date, collection) {
    return axios.get(API_URL + 'gridByDay', 
      { 
        headers: authHeader(),
        params: {
          date: date, collection: collection
        }
      }
    );
  }

  getDataFromSearch(fechaInit, fechaFin, select) {
    return axios.get(API_URL + 'datasearch', 
      { 
        headers: authHeader(), 
        params: {
          initDate: fechaInit, 
          endDate: fechaFin,
          type: select
        } 
      }
    );
  }
}

export default new UserService();