import React from "react";
import DatePicker from 'react-date-picker';
import throttle from "lodash/throttle";
import Loader from "./loader";
import UserService from "../services/user.service";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(CategoryScale);

let initialState = [true, true, true, true, true, true];

const Charts = (data) => {
    const currentDay = () => {
        let date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const parseDay = (erDate) => {
      let date = new Date(erDate);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toLocaleDateString();
  };

    let collection = data.collection;
    
    const [pageData, setPageData] = React.useState({
        loading: false,
        collection: {}
    });

    const [checkedState, setCheckedState] = React.useState(
        initialState
    );

    const [selectDate, setSelectDate] = React.useState(currentDay());
    const myChartRef = React.useRef(null);

    const showLoading = () => {
        setPageData((prevState) => ({
          ...prevState,
          loading: true,
        }));
      }

    const changesOnData = React.useRef(
        throttle((newD, states) => {
          showLoading();
          UserService.getUserGridDataWithDate(newD, collection)
          .then(response => {
            if(response.data.message == 'ERR_NOERROR') {
              let grid = response.data.data;
              let datasets = [];
              if(grid[0] && typeof grid[0].Balance === 'string') {
                datasets.push({
                  label: 'Balance',
                  data: grid.map((values) => isNaN(parseFloat(values.Balance)) ? 0 : parseFloat(values.Balance)),
                  backgroundColor: [
                      'rgba(75, 192, 192, 0.5)',
                    ],
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 2,
                    hidden: !states[0]
                })
              }
              if(grid[0] && typeof grid[0].estimated_consumption === 'string') {
                datasets.push({
                  label: 'Consumo estimado',
                  data: grid.map((values) => isNaN(parseFloat(values.estimated_consumption)) ? 0 : parseFloat(values.estimated_consumption)),
                  backgroundColor: [
                      'rgba(255, 99, 132, 0.5)',
                    ],
                    borderColor: 'rgb(255, 99, 132)',
                    borderWidth: 2,
                    hidden: !states[1]
                })
              }
              if(grid[0] && typeof grid[0].estimated_generation === 'string') {
                datasets.push({
                  label: "Generación estimada",
                  data: grid.map((values) => isNaN(parseFloat(values.estimated_generation)) ? 0 : parseFloat(values.estimated_generation)),
                  backgroundColor: [
                      'rgba(255, 205, 86, 0.5)'
                    ],
                    borderColor: 'rgb(255, 205, 86)',
                    borderWidth: 2,
                    hidden: !states[2]
                })
              }
              if(grid[0] && typeof grid[0].estimated_sold === 'string') {
                datasets.push({
                  label: 'Venta estimada',
                  data: grid.map((values) => isNaN(parseFloat(values.estimated_sold)) ? 0 : parseFloat(values.estimated_sold)),
                  backgroundColor: [
                      'rgba(153, 102, 255, 0.5)',
                    ],
                    borderColor: 'rgb(153, 102, 255)',
                    borderWidth: 2,
                    hidden: !states[3]
                })
              }
              if(grid[0] && typeof grid[0].EV_charger === 'string') {
                datasets.push({
                  label: "Carga EV",
                  data: grid.map((values) => isNaN(parseFloat(values.EV_charger)) ? 0 : parseFloat(values.EV_charger)),
                  backgroundColor: [
                      'rgba(215, 123, 0, 0.5)'
                    ],
                    borderColor: 'rgb(215, 123, 0)',
                    borderWidth: 2,
                    hidden: !states[4]
                })
              }
              if(grid[0] && typeof grid[0].Servicios_de_red === 'string') {
                datasets.push({
                  label: "Servicios de red",
                  data: grid.map((values) => isNaN(parseFloat(values.Servicios_de_red)) ? 0 : parseFloat(values.Servicios_de_red)),
                  backgroundColor: [
                      'rgba(28, 129, 16, 0.5)'
                    ],
                    borderColor: 'rgb(28, 129, 16)',
                    borderWidth: 2,
                    hidden: !states[5]
                })
              }
              setPageData({
                loading: false,
                collection: {
                    labels: grid.map((values) => values.hour),
                    datasets: datasets
                }
              })
            }
          })
        }, 200)
      )

    React.useEffect(() => {
        if(selectDate) {
          let newD = isValidDate(selectDate) ? parseDay(selectDate) : undefined;
          changesOnData.current(newD, checkedState);
        }
      }, [selectDate]);

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => 
          index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        let chart = myChartRef.current.config;
        chart.data.datasets.forEach((dataset, index) => {
            dataset.hidden = !updatedCheckedState[index];
        })
        chart.update();
    };

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    const barData = () => {
        return pageData.collection && Array.isArray(pageData.collection.datasets) && pageData.collection.datasets.length > 0 ? 
        <div className="chart-container">
            <Bar
                data={pageData.collection}
                ref={myChartRef}
                options={{
                    plugins: {
                    title: {
                        display: true,
                        text: "Información"
                    },
                    legend: {
                        display: true,
                        onClick: function (e) {
                          e.preventDefault();
                        }
                    }
                    }
                }}
            />
        </div> : 
        <div >
            <h2 style={{ textAlign: "center" }}>No hay datos que mostrar</h2>
        </div>
    }

    const inputSelect = () => {
        return pageData.collection && Array.isArray(pageData.collection.datasets) && pageData.collection.datasets.length > 0 ?
        
        <div className="col-md-6 col-lg-8 row boxCheck">
            {
              pageData.collection.datasets.map(({label, data}, index) => {
                return(
                  <div className="col-md-6 col-lg-4">
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      checked={checkedState[index]}
                      onChange={() => handleOnChange(index)}
                    />
                    <label className="label-input" htmlFor={`custom-checkbox-${index}`}>{label}</label>
                  </div>
                )
              })
            }
        </div> :
        <div >
        </div>
    }

    return (
        <>
          <div className={"col-md-12 row "+(data.isActive ? "" : "noVisible")}>
            <div className="col-md-6 col-lg-4 padding10 alignCenter">
              <label className="label-search" for="selectDate">Fecha:</label>
              <DatePicker 
                id="selectDate"
                className="input-search"
                placeholder="Init date"
                value={selectDate}
                onChange={e => setSelectDate(e)}
              />
            </div>
            {pageData.loading ? <Loader /> : inputSelect()}       
          </div>
          {
            data.isActive ? pageData.loading ? <Loader /> : barData() : <></>
          }
        </>
      );
};

export default Charts;