import React from "react";
import UserService from "../services/user.service";

const BalanceInterval = ({balance}) => {
    const [currentBalance, setCurrentBalance] = React.useState(balance);

    React.useEffect(() => {
        const interval = setInterval(() => {
            UserService.getUserBalance()
            .then(response => {
                if(response.data.message == 'ERR_NOERROR') {
                    setCurrentBalance(() => response.data.balance);
                }
            });
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="col-lg-4 col-md-4">
            <p>
                <strong>Balance:</strong>{" "}
                {currentBalance}{" "}
                CNIT
            </p>
        </div>
    );
}

export default BalanceInterval;