import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { withRouter } from '../common/with-router';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        ¡Campo obligatorio!
      </div>
    );
  }
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.onChangeProfile = this.onChangeProfile.bind(this);
    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
      name: "",
      message: ""
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true, buttonPressed: false })
  }

  onChangeProfile(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleProfile(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.changeProfile(this.state.name).then(
        () => {
          this.props.router.navigate("/profile");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  onClick() {
    this.setState({buttonPressed: true})
  }

  onCancel() {
    this.setState({buttonPressed: false, message: ""});
  }

  currentDiv() {
    const { currentUser } = this.state;
    if(this.state.buttonPressed) {
      return(
        <div>
          <Form
            onSubmit={this.handleProfile}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
                <label className="weightBold" htmlFor="name">Nombre:</label>
                <Input
                  type="text"
                  className="form-control"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChangeProfile}
                  validations={[required]}
                />
              </div>
              <p>
                <strong>Email:</strong>{" "}
                {currentUser.email}
              </p>
              <p>
                <strong>Clave pública:</strong>{" "}
                  {(currentUser.wallet && currentUser.wallet != "") ? currentUser.wallet : "Sin añadir"}{" "}
              </p>
              <div className="col-md-4 offset-4 form-group">
                <button
                  className="btn btn-primary margin01"
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Guardar</span>
                </button>
                <button
                  className="btn btn-primary margin01"
                  onClick={this.onCancel}
                  >
                    <span>Cancelar</span>
                </button>
              </div>
              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
        </div>
      )
    } else {
      return(
        <div>
          <p>
              <strong>Nombre:</strong>{" "}
              {currentUser.name}
          </p>
          <p>
              <strong>Email:</strong>{" "}
              {currentUser.email}
          </p>
          <p>
            <strong>Clave pública:</strong>{" "}
              {(currentUser.wallet && currentUser.wallet != "") ? currentUser.wallet : "Not added on database"}{" "}
          </p>
          <div className="col-md-12 alignCenter">
            <button
              className="btn btn-primary"
              disabled={this.state.loading}
              onClick={this.onClick}
            >
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>{(currentUser.name && currentUser.name != "") ? "Editar perfil" : "Añadir"}</span>
            </button>
          </div>
        </div>
      )
    }
    
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { currentUser } = this.state;

    return (
      <div className="container">
        {(this.state.userReady) ?
        <div className="row">
          <header className="col-md-12 jumbotron">
            <h3>
              Perfil <strong>{currentUser.username}</strong>
            </h3>
          </header>
          <div className="col-md-6">
            {this.currentDiv()}
          </div>
          <div className="col-md-6">
            <strong>Permisos:</strong>
            <ul>
              {currentUser.roles &&
                currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
            </ul>
          </div>
        </div>: null}
      {this.state.message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {this.state.message}
          </div>
        </div>
      )}
      </div>
    );
  }
}

export default withRouter(Profile);