import { Component } from "react";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import Table from "./table";

export default class BoardUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      redirect: null
    };
  }

  componentDidMount() {
    // const currentUser = AuthService.getCurrentUser();
    // if (!currentUser) this.setState({ redirect: "/login" });
    UserService.getUserBoard().then(
      response => {
        if(response && response.data && response.data.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
        }
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    let contratos = this.state.content.contracts;
    let uuid = this.state.content.uuid;
    return (
      <div className="container">
        <header className="jumbotron">
          <h2>{(this.state.content.title || this.state.content)}</h2>
        </header>
        <div className="row ">
        {contratos && Array.isArray(contratos) && <Table dataFrom={contratos} hash={uuid}/>}
        </div>
      </div>
    );
  }
}