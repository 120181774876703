import React, { Component } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

import AuthService from "./services/auth.service";
import AuthVerify from "./common/auth-verify";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import TxUser from "./components/transaction.component";
import DataUser from "./components/data.component.js";
import Footer from './components/Footer';

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    //const { currentUser } = this.state;

    return (
      <div className="fixedSection">
        {/* <nav className="navbar navbar-expand navbar-dark bg-antracita">
          <Link to={"/"} className="navbar-brand">
            CENIT 2.0
          </Link>
          <div className="navbar-nav mr-auto">
            {currentUser && (
              <li className="nav-item">
                <Link to={"/operations"} className="nav-link">
                  Gestión tokens
                </Link>
              </li>
              
            )}
            {currentUser && (
              <li className="nav-item">
                <Link to={"/transactions"} className="nav-link">
                  Transacciones
                </Link>
              </li>
              
            )}
            {currentUser && (
              <li className="nav-item">
                <Link to={"/data"} className="nav-link">
                  Información energética
                </Link>
              </li>
              
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  Salir
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Acceder
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Registro
                </Link>
              </li>
            </div>
          )}
        </nav> */}

        <div className="container mt-3 margin-bottom1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/transactions" element={<BoardUser />} />
            <Route path="/operations" element={<TxUser />} />
            <Route path="/data" element={<DataUser />} />
          </Routes>
        </div>
        <Footer />
        <AuthVerify logOut={this.logOut}/>
        
      </div>
    );
  }
}

export default App;