import React from "react";
import DatePicker from 'react-date-picker';
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "./pagination";
import Loader from "./loader";
import UserService from "../services/user.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange, faFileText, faLongArrowLeft, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';

  const Table = (data) => {
    const firstDay = () => {
      let date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    const lastDay = () => {
      let date = new Date();
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    let uuid = data.hash;
    const countPerPage = 15;
    const [pageData, setPageData] = React.useState({
      rowData: [],
      loading: false,
      totalContracts: 0,
      collection: []
    });
    const [initDate, setInitDate] = React.useState(firstDay());
    const [endDate, setEndDate] = React.useState(lastDay());
    const [select, setSelect] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const showLoading = () => {
      setPageData((prevState) => ({
        ...prevState,
        rowData: [],
        loading: true,
      }));
    }

    const changesOnData = React.useRef(
      throttle((initD, endD, sel) => {
        showLoading();
        UserService.getDataFromSearch(initD, endD, sel)
        .then(response => {
          if(response.data.message == 'ERR_NOERROR') {
            let contracts = response.data.contracts;
            setCurrentPage(1);
            setPageData({
              totalContracts: contracts.length,
              rowData: contracts,
              loading: false,
              collection: cloneDeep(contracts.slice(0, countPerPage))
            })
          }
        })
      }, 400)
    )
  
    React.useEffect(() => {
      if(endDate || initDate || select) {
        let endD = isValidDate(endDate) ? new Date(endDate).toISOString() : undefined;
        let initD = isValidDate(initDate) ? new Date(initDate).toISOString() : undefined;
        changesOnData.current(initD, endD, select);
      } else {
        updatePage(1);
      }
    }, [endDate, select, initDate]);
  
    const updatePage = p => {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      let newData = cloneDeep(pageData.rowData.slice(from, to));
      setPageData((prevState) => ({
        ...prevState,
        collection: newData
      }))
    };
  
    const parseDate = (erDate) => {
        let date = new Date(erDate);
        return ('0' + date.getUTCHours()).slice(-2)+':'+('0' + date.getUTCMinutes()).slice(-2)+
          ' '+('0' + date.getUTCDate()).slice(-2)+'/'+('0' + (date.getUTCMonth() + 1)).slice(-2)+'/'+date.getUTCFullYear()
    }

    const isValidDate = (d) => {
      return d instanceof Date && !isNaN(d);
    }
  
    const tableData = () => {
        return pageData.collection.length == 0 ? <tr className="tdBuyer"><td>No hay datos</td></tr> : pageData.collection.map(elem => {
            let seller = elem.seller == uuid;
            let fecha = parseDate(elem.starttime);
            return (
              <tr className={seller ? ("tdSeller") : ("tdBuyer")}>
                <td>{seller ?
                (<FontAwesomeIcon icon={faLongArrowLeft} />) :
                <FontAwesomeIcon icon={faLongArrowRight} />}</td>
                <td>{fecha}</td>
                <td>{elem.estimatedsold}</td>
                <td>{elem.percentage}</td>
                <td>{elem.energyprice}</td>
                <td>{elem.amount}</td>
                <td className="alignCenter"><a target="_blank" href={elem.url}><FontAwesomeIcon icon={faFileText} /></a></td>
            </tr>
            )
        })
    };
  
    const headRow = () => {
        return(
            <tr>
                <th><FontAwesomeIcon icon={faExchange} /></th>
                <th>Fecha</th>
                <th>Energía (KWh)</th>
                <th>Energía (%)</th>
                <th>Precio compra (€)</th>
                <th>Cantidad token (CNIT)</th>
                <th>Contrato</th> 
            </tr>
        )
    };
  
    return (
      <>
        <div className="col-md-12 row">
          <div className="col-md-6 col-lg-4 padding10 alignCenter">
            <label className="label-search" for="initDate">Fecha inicio:</label>
            <DatePicker 
              id="initDate"
              className="input-search"
              placeholder="Init date"
              value={initDate}
              onChange={e => setInitDate(e)}
            />
          </div>
          <div className="col-md-6 col-lg-4 padding10 alignCenter">
            <label className="label-search" for="endDate">Fecha fin:</label>
            <DatePicker
              id="endDate"
              className="input-search"
              placeholder="End date"
              value={endDate}
              onChange={e => setEndDate(e)}
            />
          </div>
          <div className="col-md-6 col-lg-4 padding10 alignCenter">
            <label className="label-search" for="txtype">Tipo Tx:</label>
            <select id="txtype" value={select} className="input-search" onChange={e => setSelect(e.target.value)}>
              <option value="both">Todos</option>
              <option value="buyer">Compras</option>
              <option value="seller">Ventas</option>
            </select>
          </div>
        </div>
        {pageData.loading ? <Loader /> : 
          <table>
            <thead>
              {headRow()}
            </thead>
            <tbody>{tableData()}</tbody>
          </table>
        }
        <Pagination
          totalRows={pageData.totalContracts}
          pageChangeHandler={updatePage}
          rowsPerPage={countPerPage}
        />
      </>
    );
  };
  export default Table;