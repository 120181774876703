import axios from "axios";
import authHeader from './auth.header';

const API_URL = 'https://cenit20.logikers.com/auth/';
//const API_URL = '/auth/';

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "signin", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  changeProfile(name) {
    return axios
      .post(API_URL + "editprofile", {
        name
      }, { headers: authHeader() })
      .then(response => {
        if(response.data.name) {
          let erUser = this.getCurrentUser();
          erUser.name = response.data.name;
          erUser.username = response.data.username;
          localStorage.setItem("user", JSON.stringify(erUser));
        }
        return response.data;
      });
  }

  buyTokens() {
    return axios
      .post(API_URL + "assignTokens", {},
      { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    }).then(response => {
      return response.data;
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();